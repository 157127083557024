import { commonrequest } from "./commonRequest";

const BASE_URL = process.env.REACT_APP_API;

// user login api
export const verifyPasswordApi = async (data, header) => {
  return await commonrequest("POST", `${BASE_URL}/login/password`, data, header);
}

export const requestOtpApi = async (data, header) => {
  return await commonrequest("POST", `${BASE_URL}/login/otp-request`, data, header);
}

export const verifyOtpApi = async (data, header) => {
  return await commonrequest("POST", `${BASE_URL}/login/otp-verify`, data, header);
}

export const refreshAccessTokenApi = async (header) => {
  return await commonrequest("POST", `${BASE_URL}/login/refresh-access-token`, null, header);
}

// forgot password api
export const forgotPassword = async (data, header) => {
  const url = `${BASE_URL}/login/forgot-password`;
  return await commonrequest("POST", url, data, header);
}

// resetPassword api
export const resetPassword = async (data, header) => {
  const url = `${BASE_URL}/login/reset-password`;
  return await commonrequest("POST", url, data, header);
}

// user activates self by setting their password for the first time
export const activateUser = async (data, header) => {
  const url = `${BASE_URL}/login/activate-user`;
  return await commonrequest("POST", url, data, header);
}

export const getMe = async (nul) => {
  return await commonrequest("GET", `${BASE_URL}/me`, null, nul);
}

// upload pdf
export const UploadPdfApi = async (data, header, queryParams) => {
  let IsJson = "form"
  return await commonrequest("POST", `${BASE_URL}/pdf?${queryParams.join('&')}`, data, header, IsJson);
}

// download pdf
export const downloadPdfApi = async (data, header) => {
  return await commonrequest("GET", `${BASE_URL}/pdf/${data}`, data, header);
}

export const downloadPdfXlsxApi = async (data, header) => {
  return await commonrequest("GET", `${BASE_URL}/pdf/${data}/xlsx`, data, header);
}

export const sampleInferIQApi = async (data, header) => {
  return await commonrequest("POST", `${BASE_URL}/inferIQJson?page=1&pageSize=5`, data, header);
}

// Get The Extract Data
export const getExtractDataApi = async (data, header) => {
  return await commonrequest("GET", `${BASE_URL}/extraction?pageSize=${data?.pageSize}&page=${data?.page}`, data, header);
}

// api's created by developer after 14th June 2024//
// get Users
export const getUsers = async (data, header) => {
  const url = `${BASE_URL}/user`;
  return await commonrequest("GET", url, "", header);
};

// get all users of a customer
export const getUsersOfCustomer = async (data, header) => {
    const queryParams = [];
    if (data.activatedOnly) {
        queryParams.push(`activatedOnly=${data.activatedOnly}`);
    }
    let url = `${BASE_URL}/user/customer`;
    if (queryParams.length > 0) {
        const queryString = queryParams.join("&");
        url += `?${queryString}`;
    }

    return await commonrequest("GET", url, null, header);
};

// create new user
export const createUser = async (data, header) => {
  return await commonrequest("POST", `${BASE_URL}/user`, data, header);
}

// get user by id 
export const getUserById = async (data, header) => {
  const url = `${BASE_URL}/user/${data}`;
  return await commonrequest("GET", url, "", header);
};

// update user by id 
export const updateUser = async (data, header) => {
  const url = `${BASE_URL}/user/${data?.UserID}`;
  return await commonrequest("PUT", url, data, header);
};

export const convertUserToAdmin = async (userSequence, header) => {
  const url = `${BASE_URL}/user/${userSequence}/convert-to-admin`;
  return await commonrequest("POST", url, null, header);
};

export const getAdmins = async (header) => {
  const url = `${BASE_URL}/admin`;
  return await commonrequest("GET", url, null, header);
};

export const getAdminById = async (adminSequence, header) => {
  const url = `${BASE_URL}/admin/${adminSequence}`;
  return await commonrequest("GET", url, null, header);
};

export const updateAdmin = async (data, header) => {
  const url = `${BASE_URL}/admin/${data?.UserID}`;
  return await commonrequest("PUT", url, data, header);
};

// delete user by id 
export const deleteUserById = async (data, header) => {
  const url = `${BASE_URL}/user/${data}`;
  return await commonrequest("DELETE", url, {}, header);
};

// get Cases 
export const getCases = async (data, header) => {
  // console.log("getCases data:", data);
  const queryParams = [];
  if (data.startDate) {
    queryParams.push(`startDate=${data.startDate}`);
  }
  if (data.endDate) {
    queryParams.push(`endDate=${data.endDate}`);
  }
  let url = `${BASE_URL}/case`;
  if (queryParams.length > 0) {
    const queryString = queryParams.join("&");
    url = `${BASE_URL}/case?${queryString}`;
  }

  return await commonrequest("GET", url, null, header);
};

// create new case
export const createCase = async (data, header) => {
  return await commonrequest("POST", `${BASE_URL}/case`, data, header);
}

// get case by id 
export const getCaseById = async (caseSequence, header) => {
  const url = `${BASE_URL}/case/${caseSequence}`;
  return await commonrequest("GET", url, "", header);
};

export const getCaseAssignedUsers = async (data, header) => {
  const url = `${BASE_URL}/case/assign/${data}`;
  return await commonrequest("GET", url, "", header);
};

// get allcases for filter
export const getCasesData = async (data, header) => {
  // const url = `${BASE_URL}/cases/data`;
  const url = `${BASE_URL}/case`;
  return await commonrequest("GET", url, "", header);
};

// update case by id 
export const updateCase = async (data, header) => {
  const url = `${BASE_URL}/case/${data?.sequence}`;
  return await commonrequest("PUT", url, data, header);
};

// delete case by id 
export const deleteCaseById = async (data, header) => {
  const url = `${BASE_URL}/case/${data}`;
  return await commonrequest("DELETE", url, {}, header);
};

// get Accounts
export const getAccounts = async (data, header) => {
  console.log("getAccounts data:", data);
  // const queryParams = [];
  // if (data.page) {
  //   queryParams.push(`page=${data.page}`);
  // }
  // if (data.pageSize) {
  //   queryParams.push(`pageSize=${data.pageSize}`);
  // }
  // if (data.sortBy) {
  //   queryParams.push(`sortBy=${data.sortBy}`);
  // }
  // if (data.search) {
  //   queryParams.push(`search=${data.search}`);
  // }
  // if (data.startDate) {
  //   queryParams.push(`startDate=${data.startDate}`);
  // }
  // if (data.endDate) {
  //   queryParams.push(`endDate=${data.endDate}`);
  // }
  // if (data.payment_plan) {
  //   queryParams.push(`payment_plan=${data.payment_plan}`);
  // }
  // const sortOrder = data.sortOrder;
  // if (sortOrder) {
  //   queryParams.push(`sortOrder=${sortOrder}`);
  // }
  // const queryString = queryParams.join("&");
  // const url = `${BASE_URL}/customer?${queryString}`;
  const url = `${BASE_URL}/customer`;

  return await commonrequest("GET", url, "", header);
};

// create new account
export const createAccount = async (data, header) => {
  return await commonrequest("POST", `${BASE_URL}/customer`, data, header);
}

// get customer by id
export const getCustomerById = async (customerSequence, header) => {
  const url = `${BASE_URL}/customer/${customerSequence}`;
  return await commonrequest("GET", url, "", header);
};

// update customer by id
export const updateCustomer = async (data, header) => {
  const url = `${BASE_URL}/customer/${data?.customerSequence}`;
  return await commonrequest("PUT", url, data, header);
};

// delete account by id 
export const deleteAccountById = async (data, header) => {
  const url = `${BASE_URL}/customer/${data}`;
  return await commonrequest("DELETE", url, {}, header);
};

export const getFilesData = async (data, header) => {
  const queryParams = [];
  if (data.caseNumber) {
    queryParams.push(`caseId=${data.caseNumber}`);
  }
  if (data.documentType) {
    queryParams.push(`documentType=${data.documentType}`);
  }
  if (data.startDate) {
    queryParams.push(`startDate=${data.startDate}`);
  }
  if (data.endDate) {
    queryParams.push(`endDate=${data.endDate}`);
  }
  if (data.status) {
    queryParams.push(`status=${data.status}`);
  }

  let url = `${BASE_URL}/pdf`;
  if (queryParams.length > 0) {
    const queryString = queryParams.join("&");
    url += `?${queryString}`;
  }

  return await commonrequest("GET", url, "", header);
};

export const upodateJsonFileData = async (data, header) => {
  return await commonrequest("POST", `${BASE_URL}/inferIQUpdate`, data, header);
}

export const getAllCompaniesJson = async (data, header) => {
  const url = `${BASE_URL}/customer`;
  return await commonrequest("GET", url, "", header);
};

export const assignUsers = async (data, header) => {
  const url = `${BASE_URL}/case/assign/${data.caseId}`;
  return await commonrequest("PUT", url, data.users, header);
};

export const getSettings = async (data, header) => {
  const url = `${BASE_URL}/settings`;
  return await commonrequest("GET", url, "", header);
};

export const updateSetting = async (data, header) => {
  const url = `${BASE_URL}/settings`;
  return await commonrequest("POST", url, data, header);
};

export const deleteFile = async (data, header) => {
  const url = `${BASE_URL}/pdf/${data}`;
  return await commonrequest("DELETE", url, {}, header);
};
// api's created by developer after 14th June 2024//

// YF login token
export const generateYfLoginToken = async (header) => {
  return await commonrequest("POST", `${BASE_URL}/yf/login-token`, {}, header);
};

export const deleteYellowfinLoginToken = async (yfLoginTokenId, header) => {
  return await commonrequest("DELETE", `${BASE_URL}/yf/login-token/${yfLoginTokenId}`, null, header);
};

// account (i.e., a bank account info from a PDF document upload)
export const getAccount = async (accountId, header) => {
  return await commonrequest("GET", `${BASE_URL}/account/${accountId}`, "", header);
};

export const editAccount = async (accountId, data, header) => {
  /*
  {
    "bankName": "CITI BANK",
    "account": {
      "accountNumber": "80094982",
      "accountOwner1": "Test Owner"
    }
  }
  */
  return await commonrequest("PUT", `${BASE_URL}/account/${accountId}`, data, header);
};

// statement
export const getStatement = async (statementId, header) => {
  return await commonrequest("GET", `${BASE_URL}/statement/${statementId}`, "", header);
};

export const editStatement = async (statementId, data, header) => {
  /*
  {
    "beginningDate": "2024-07-12",
    "endingDate": "2024-08-11",
    "beginningBalance": 999.99,
    "endingBalance": 1234.56
  }
  */
  return await commonrequest("PUT", `${BASE_URL}/statement/${statementId}`, data, header);
};

// transaction
// export const getTransaction = async (transactionId, header) => {
//   return await commonrequest("GET", `${BASE_URL}/transaction/${transactionId}`, "", header);
// };

export const getTransactionsOnSamePage = async (transactionId, header) => {
  return await commonrequest("GET",
      `${BASE_URL}/transaction/${transactionId}/page`,
      "",
      header
  );
};

export const editTransaction = async (transactionId, data, header) => {
  /*
  {
    "transactionDate": "2024-08-07",
    "checkNumber": "1234",
    "description": "test check",
    "reference": "abcd",
    "debit": 123.45,
    "credit": 0.00,
    "balance": -123.45
  }
  */
  return await commonrequest("PUT", `${BASE_URL}/transaction/${transactionId}`, data, header);
};

// export const getTransactionsByPdfPage = async (pdfDocumentSeq, page, header) => {
//   return await commonrequest("GET",
//       `${BASE_URL}/pdf/${pdfDocumentSeq}/page/${page}`,
//       "",
//       header
//   );
// };

export const createTransaction = async (statementSeq, pdfDocumentSeq, pageNumber, data, header) => {
  /*
  {
    "transactionDate": "2024-08-07",
    "checkNumber": "1234",
    "description": "test check",
    "reference": "abcd",
    "debit": 123.45,
    "credit": 0.00,
    "balance": -123.45
  }
  */
  return await commonrequest(
      "POST",
      `${BASE_URL}/transaction/statement/${statementSeq}/pdf/${pdfDocumentSeq}/page/${pageNumber}`,
      data,
      header
  );
};

export const deleteTransaction = async (transactionId, header) => {
  return await commonrequest("DELETE", `${BASE_URL}/transaction/${transactionId}`, "", header);
};

export const getPdfByAccount = async (accountId, header) => {
  return await commonrequest("GET",
      `${BASE_URL}/pdf/account/${accountId}`,
      "",
      header
  );
};

export const getPdfByStatement = async (statementId, header) => {
  return await commonrequest("GET",
      `${BASE_URL}/pdf/statement/${statementId}`,
      "",
      header
  );
};

export const getPdfByTransaction = async (transactionId, header) => {
  return await commonrequest("GET",
      `${BASE_URL}/pdf/transaction/${transactionId}`,
      "",
      header
  );
};

// Registration - Primary Admin
export const registerPrimaryAdmin = async (data, header) => {
  /*
  Register/request OTP:
  {
      "admin": {
          "firstName": "John",
          "lastName": "Doe",
          "email": "johndoe@example.com",
          "phone": 1234567890
      },
      "customer": {
          "companyName": "Example Corp",
          "companyStreetAddress": "123 Example St",
          "companyCity": "Example City",
          "companyState": "EX",
          "companyPostalCode": "12345",
          "companyCountryCode": "US",
          "paymentPlan": "ABCDE"
      }
  }

  Verify OTP:
  {
      "email": "johndoe@example.com",
      "otp": "123456"
  }

  Resend OTP:
  {
      "email": "johndoe@example.com"
  }
  */
  return await commonrequest("POST", `${BASE_URL}/register/primary-admin`, data, header);
};

// get Chargify.js security token
export const getChargifyjsSecurityToken = async (header) => {
  return await commonrequest("GET", `${BASE_URL}/maxio/security-token`, "", header);
};

// Create Maxio Subscription for logged in primary Admins's Customer using Chargify.js payment token
export const createMaxioSubscriptionWithPaymentToken = async (data, header) => {
  /*
  {
      "token": "tok_vsvypky56x68h39qnssnkhxq"
  }
  */
  return await commonrequest("POST", `${BASE_URL}/maxio/subscription/payment-token`, data, header);
};

// Create Maxio Subscription for logged in primary Admins's Customer using payment authorizationg code (e.g., for checks)
export const createMaxioSubscriptionWithPaymentAuthCode = async (data, header) => {
  /*
  {
      "code": "AR38XCDVZFHNM3NY"
  }
  */
  return await commonrequest("POST", `${BASE_URL}/maxio/subscription/payment-auth-code`, data, header);
};

// get Maxio billing portal link for a Customer
export const getMaxioBillingPortalLink = async (header) => {
  return await commonrequest("GET", `${BASE_URL}/maxio/billing-portal-link`, null, header);
};

// get current plan and Maxio subscription info
export const getCurrentSubscriptionInfo = async (header) => {
  return await commonrequest("GET", `${BASE_URL}/maxio/current-subscription-info`, null, header);
};

// schedule plan change
export const schedulePlanChange = async (planName, header) => {
  return await commonrequest("POST", `${BASE_URL}/maxio/schedule-plan-change/${planName}`, null, header);
};

// get any already scheduled pending plan changes
export const getScheduledPendingPlanChanges = async (header) => {
  return await commonrequest("GET", `${BASE_URL}/scheduled-plan-changes/pending`, null, header);
};

// cancel pending plan changes
export const cancelPendingPlanChanges = async (header) => {
  return await commonrequest("DELETE", `${BASE_URL}/scheduled-plan-changes/pending`, null, header);
};
